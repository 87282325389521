import React from "react"

type AimType = "aim-l" | "aim-r" | "aim-full"

type AimProps = {
  className?: string
  type?: AimType
  fly?: boolean
  adaptive?: boolean
  children?: React.ReactNode | null
}

export function Aim({ children, className = "", type = "aim-full", fly = false, adaptive = true }: AimProps) {
  let paddingClass = "p-3 md:p-6"
  let leftOffset = "left-0"
  let topOffset = "top-0"
  let rightOffset = "right-0"
  let bottomOffset = "bottom-0"

  if (fly) {
    paddingClass = ""
    leftOffset = "left-3 md:left-6"
    topOffset = "top-3 md:top-6"
    rightOffset = "right-3 md:right-6"
    bottomOffset = "bottom-3 md:bottom-6"
  }

  const borderSize = `w-[40px] h-[40px] ${adaptive ? "md:w-[80px] md:h-[80px]" : ""}`

  return (
    <div className={`relative ${paddingClass} ${className}`}>
      {["aim-l", "aim-full"].includes(type) && (
        <div
          className={`absolute ${leftOffset} ${topOffset} border-primary border-l-2 border-t-2 rounded-tl-[12px] ${borderSize}`}
        />
      )}
      {["aim-r", "aim-full"].includes(type) && (
        <div
          className={`absolute ${rightOffset} ${topOffset} border-primary border-r-2 border-t-2 rounded-tr-[12px] ${borderSize}`}
        />
      )}
      {["aim-l", "aim-full"].includes(type) && (
        <div
          className={`absolute ${rightOffset} ${bottomOffset} border-primary border-r-2 border-b-2 rounded-br-[12px] ${borderSize}`}
        />
      )}
      {["aim-r", "aim-full"].includes(type) && (
        <div
          className={`absolute ${leftOffset} ${bottomOffset} border-primary border-l-2 border-b-2 rounded-bl-[12px] ${borderSize}`}
        />
      )}
      {children}
    </div>
  )
}
