import React from "react"

export function Lighter({
  h,
  w,
  color = "pink",
  className,
}: {
  h?: number | string
  w?: number | string
  color?: "pink" | "purple"
  className?: string
}) {
  if (color === "pink") {
    return (
      <svg
        height={h}
        width={w}
        className={className}
        viewBox="0 0 294 294"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_10918_82639)">
          <g filter="url(#filter0_f_10918_82639)">
            <circle cx="147" cy="-5" r="60" fill="#FF46AA" fillOpacity="0.75" />
          </g>
          <path d="M23 0.5L269 0.500022" stroke="url(#paint0_linear_10918_82639)" strokeWidth="4" />
        </g>
        <defs>
          <filter
            id="filter0_f_10918_82639"
            x="7"
            y="-145"
            width="280"
            height="280"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="40" result="effect1_foregroundBlur_10918_82639" />
          </filter>
          <linearGradient
            id="paint0_linear_10918_82639"
            x1="269"
            y1="0.499085"
            x2="23"
            y2="0.499719"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF46AA" stopOpacity="0" />
            <stop offset="0.5" stopColor="#FF46AA" />
            <stop offset="1" stopColor="#FF46AA" stopOpacity="0" />
          </linearGradient>
          <clipPath id="clip0_10918_82639">
            <rect width="294" height="294" fill="white" />
          </clipPath>
        </defs>
      </svg>
    )
  } else {
    return (
      <svg
        height={h}
        width={w}
        className={className}
        viewBox="0 0 294 294"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_10918_82640)">
          <g filter="url(#filter0_f_10918_82640)">
            <circle cx="147" cy="-5" r="60" fill="#993CFF" />
          </g>
          <path d="M23 0L269 2.1506e-05" stroke="url(#paint0_linear_10918_82640)" strokeWidth="4" />
        </g>
        <defs>
          <filter
            id="filter0_f_10918_82640"
            x="7"
            y="-145"
            width="280"
            height="280"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="40" result="effect1_foregroundBlur_10918_82640" />
          </filter>
          <linearGradient
            id="paint0_linear_10918_82640"
            x1="269"
            y1="-0.000914933"
            x2="23"
            y2="-0.000280863"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#8C37F1" stopOpacity="0" />
            <stop offset="0.53125" stopColor="#B069FF" />
            <stop offset="1" stopColor="#A447F9" stopOpacity="0" />
          </linearGradient>
          <clipPath id="clip0_10918_82640">
            <rect width="294" height="294" fill="white" />
          </clipPath>
        </defs>
      </svg>
    )
  }
}
