import React, { useRef, useState } from "react"

import { useTailwindBreakpoints } from "components/hooks/use-tailwind-breakpoints"

import { Box } from "components/shared/box"
import { PlayVideoButton } from "components/shared/icons/play-video-button"
import { ModalWrapper } from "components/shared/modal-wrapper"

type ShareLinkModalProps = {
  close: () => void
  title?: React.ReactNode | string
  posterSrc?: string
  videoSrc?: string
}

export function VideoModal({ close, title, posterSrc, videoSrc }: ShareLinkModalProps) {
  const [isClickedPlay, setClickedPlay] = useState<boolean>(false)
  const videoRef = useRef<HTMLVideoElement>(null)
  const { isMobile } = useTailwindBreakpoints()

  const play = () => {
    setClickedPlay(true)
    videoRef?.current?.play()
  }

  return (
    <ModalWrapper close={close} title={title} showClose showAim={!isMobile}>
      <Box className="relative">
        <video ref={videoRef} className="z-1" poster={posterSrc} controls>
          <source src={videoSrc} type="video/mp4" />
          Sorry, your browser doesn&apos;t support embedded videos, but don&apos;t worry, you can{" "}
          <a href={videoSrc}>download it</a>
          and watch it with your favorite video player!
        </video>
        {!isClickedPlay ? (
          <div
            className="flex flex-col absolute justify-center items-center w-full h-full cursor-pointer z-10"
            onClick={() => play()}
          >
            <PlayVideoButton className="w-[15rem] h-[15rem] cursor-pointer " />
          </div>
        ) : null}
      </Box>
    </ModalWrapper>
  )
}
