import { useCallback, useState } from "react"
import { useNoScroll } from "./use-no-scroll"

export function useModal(isOpen = false) {
  const [modalIsOpen, setIsOpen] = useState<boolean>(isOpen)
  const { setNoScroll } = useNoScroll()

  const openModal = useCallback(() => {
    setIsOpen(true)
    setNoScroll(true)
  }, [setNoScroll])

  const closeModal = useCallback(() => {
    setIsOpen(false)
    setNoScroll(false)
  }, [setNoScroll])

  return { modalIsOpen, openModal, closeModal }
}
