import Image from "next/image"
import { useCallback } from "react"

import { Aim } from "./aim"
import { Box } from "./box"
import { GradientBox } from "./landing/gradient-box"
import { Text } from "./text"

function AimWrapper({
  className,
  children,
  showAim,
}: {
  className: string
  children: React.ReactNode
  showAim: boolean
}) {
  return showAim ? <Aim className={className}>{children}</Aim> : <>{children}</>
}

type ModalWrapperProps = {
  showClose?: boolean
  showAim?: boolean
  full?: boolean
  title?: string | React.ReactNode
  close?: () => void
  children: React.ReactNode
  light?: boolean
  noGradient?: boolean
}

export function ModalWrapper({
  showClose,
  title,
  close,
  children,
  showAim = false,
  full,
  light = false,
  noGradient = false,
}: ModalWrapperProps) {
  const handleClose = useCallback(() => {
    close?.()
  }, [close])
  const BoxTag = noGradient ? Box : GradientBox

  return (
    <div className={`fixed inset-0 ${light ? "bg-black-60" : "bg-black/5"} backdrop-blur-3xl z-50`}>
      <Box full className="overflow-y-auto h-[var(--screen-height)]">
        <Box className={["m-auto", full ? "w-full" : ""].join(" ")}>
          <AimWrapper
            showAim={showAim}
            className={[
              "flex justify-center items-center relative p-4 shrink-0 w-full rounded-2xl",
              full ? "" : "md:max-w-[708px]",
            ].join(" ")}
          >
            <BoxTag
              className={[
                noGradient ? "bg-[#3F0892] shadow-[0_0_6rem_#683CA3dd]" : "",
                "relative safe-mx my-4 shrink-0 max-h-[calc(var(--screen-height)-2rem)] rounded-2xl",
                "max-w-[calc(var(--screen-width)-calc(env(safe-area-inset-left,1rem)+env(safe-area-inset-right,1rem)))]",
                full ? "" : "md:w-[640px]",
              ].join(" ")}
            >
              {showClose && (
                <div className="absolute right-8 top-8 cursor-pointer">
                  <Image
                    src="/images/shared/modal-wrapper/close.svg"
                    alt="close icon"
                    width={15}
                    height={15}
                    onClick={handleClose}
                  />
                </div>
              )}
              {title && (
                <Box className={`w-full ${showClose && "mt-15"}`}>
                  <Text as="h3" className="text-center">
                    {title}
                  </Text>
                </Box>
              )}
              <Box full className="p-4 overflow-y-auto items-center">
                {children}
              </Box>
            </BoxTag>
          </AimWrapper>
        </Box>
      </Box>
    </div>
  )
}
