import React from "react"

import { Links } from "config/links"

import { BackgroundImage } from "components/shared/background-img"
import { Box } from "components/shared/box"
import { Section } from "components/shared/landing/section"
import { Link } from "components/shared/link"

export function RoadmapSection() {
  return (
    <Section className="mb-14 px-4 mt-10" header="Roadmap">
      <Box className="w-full">
        <Box className="text-h4 font-medium text-center">Explore the detailed Cryptobots Roadmap</Box>

        <Box className="mt-10 rounded-2xl overflow-hidden">
          <BackgroundImage
            imageSrc="/images/pages/index/roadmap-section/roadmap.webp"
            className="flex bg-right bg-no-repeat relative pt-[14rem] pb-[4rem]"
          >
            <Box className="mx-auto">
              <Link href={Links.ROADMAP}>
                <Box className="text-white uppercase py-3.5 px-8 font-semibold text-shadow-without-blur rounded-sm border border-[2px] border-[#C4C4C450]">
                  explore roadmap
                </Box>
              </Link>
            </Box>
          </BackgroundImage>
        </Box>
      </Box>
    </Section>
  )
}
