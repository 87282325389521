const clippedCorners = {
  lb: { lb: true },
  rb: { rb: true },
  lt: { lt: true },
  rt: { rt: true },
  lb_rt: { lb: true, rt: true },
  rb_lt: { rb: true, lt: true },
  full: { lb: true, rb: true, lt: true, rt: true },
}

export default clippedCorners
