import { useRouter } from "next/router"

import { useModal } from "components/hooks/use-modal"

import { VideoModal } from "components/modals/video-modal"
import { BackgroundImage } from "components/shared/background-img"
import { Box } from "components/shared/box"
import { PlayVideoButton } from "components/shared/icons/play-video-button"
import { Section } from "components/shared/landing/section"
import { Link } from "components/shared/link"
import { Text } from "components/shared/text"

export function DescriptionSection() {
  const { modalIsOpen, openModal, closeModal } = useModal()
  const { pathname } = useRouter()

  return (
    <>
      {modalIsOpen && (
        <VideoModal
          title={<span className="text-[1.2rem] md:text-[2rem]"></span>}
          close={closeModal}
          posterSrc="/images/pages/index/description-section/video_poster.webp"
          videoSrc="/video/stable-economy.mp4"
        />
      )}

      <BackgroundImage imageSrc="/images/pages/index/description-section/video_poster.webp" className="bg-top relative">
        <Box className="h-[50px] md:h-[78px] w-full bg-cover bg-left absolute top-0 left-0 right-0 bg-[url(/images/pages/index/description-section/top-border.svg)] z-1" />
        <Box className="invisible md:visible h-[78px] w-full bg-cover bg-right absolute bottom-0 left-0 right-0 bg-[url(/images/pages/index/description-section/bottom-border.svg)] z-1" />
        <Box className="absolute left-0 top-0 md:top-0 bottom-0 right-0 md:right-1/2 bg-gradient-to-t md:bg-gradient-to-r from-[#3E0891] md:from-[#3E0891dd] via-[#3E089100] z-2" />

        <Section className="!mb-0 relative pt-72 pb-16 md:py-40 z-3">
          <PlayVideoButton
            onClick={openModal}
            className="md:absolute mx-auto top-[calc(50%-7.5rem)] left-[calc(80%-7.5rem)] w-[15rem] h-[15rem] cursor-pointer "
          />

          <Box className="flex-1 justify-center md:ml-4 mt-4 md:mt-0 md:pr-8 max-w-[580px]">
            <Text as="h2" className="">
              First economic
              <br />
              -centered game
            </Text>
            <p className="text-base text-white mt-4 md:mt-6">
              Brand new economic model to disrupt
              <br />
              the Ponzi-like {pathname !== "/landing" && "play2earn"} market!
              <br />
              <br />
              {/*<strong>Controlled inflation</strong>: 10% YOY inflation*/}
              {/*<br />*/}
              {/*after first N months*/}
            </p>
            <Box className="gap-7 md:gap-10 mt-6 md:flex-row md:items-center">
              <Link className="underline font-semibold" href="https://gitbook.cryptobots.me/cryptobots/in-game-economy">
                Explore economy
              </Link>
            </Box>
          </Box>
        </Section>
      </BackgroundImage>
    </>
  )
}
