import React from "react"

export function PlayVideoButton({ className, onClick }: { className?: string; onClick?: () => void }) {
  return (
    <svg onClick={onClick} className={className} viewBox="0 0 240 240" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="240" height="240" rx="120" fill="#FF46AA" fillOpacity="0.05" />
      <rect x="40" y="40" width="160" height="160" rx="80" fill="#FF46AA" fillOpacity="0.1" />
      <rect x="80" y="80" width="80" height="80" rx="40" fill="#FF46AA" />
      <g filter="url(#filter0_d_10823_84137)">
        <path
          d="M132.721 121.354C133.626 120.67 133.626 119.33 132.721 118.646L113.804 104.358C112.659 103.493 111 104.294 111 105.712L111 134.288C111 135.706 112.659 136.507 113.804 135.642L132.721 121.354Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_10823_84137"
          x="111"
          y="104"
          width="22.4"
          height="36"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10823_84137" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_10823_84137" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
